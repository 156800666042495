import React, { useEffect, useRef } from 'react';

import ChartJS, { ChartOptions } from 'chart.js/auto';
import 'chartjs-adapter-moment';
import annotationPlugin, { AnnotationOptions } from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';

import { Chart } from 'react-chartjs-2';

import { Alert, Card, CardMedia, Skeleton, Theme } from '@mui/material';
import AspectRatioBox from 'components/AspectRatioBox';
import { useTheme } from '@mui/system';

import { useZoomTimeRangeContext } from 'context/zoom/ZoomTimeRangeContext';
import { ScaleOptionsByType } from 'chart.js';
import moment from 'moment';

ChartJS.register(annotationPlugin);
ChartJS.register(zoomPlugin);

type TimeLineAdditionalAxis = {
  id: string;
  type: string;
  display: boolean;
  position: 'left' | 'right';
  maxYAxisValue?: number;
  minYAxisValue?: number;
  label: string;
};

export type TimeLineCardOptions = {
  title?: string;
  yAxisLabel?: string;
  xAxisLabel?: string;
  maxYAxisValue?: number;
  minYAxisValue?: number;
  warningLevel?: number;
  criticalLevel?: number;
  tooltipPrecision?: number;
  legendPosition?: 'center' | 'left' | 'top' | 'right' | 'bottom' | 'chartArea';
  additionalYAxis?: TimeLineAdditionalAxis[];
};

type TimeLineCardProps = {
  data: any;
  loading: boolean;
  err?: string;
  aspectRatio?: string | number;
  options?: TimeLineCardOptions;
};

export function getTickValueAsString(value: string, index: number, values: { value: number; major: boolean }[]): string {
  const date = moment(values[index].value);
  const currentHour = date.toDate().getHours();
  const isMidnightHour = currentHour === 0;
  if (index === 0 || isMidnightHour) {
    return date.format('YYYY-MM-DD');
  }

  return `${String(currentHour).padStart(2, '0')}:00`;
}

const TimeLineCard = ({ data, loading, err, options, aspectRatio = 7 }: TimeLineCardProps) => {
  const chartRef = useRef<ChartJS>(null);
  const { zoomRange, setZoomRange } = useZoomTimeRangeContext();

  useEffect(() => {
    const { current: chart } = chartRef;
    if (!chart || !zoomRange) return;

    chart.zoomScale('x', zoomRange);
    chart.render();
  }, [zoomRange]);

  function getAnnotations(theme: Theme, options?: TimeLineCardOptions): Record<string, AnnotationOptions> | undefined {
    if (!options?.warningLevel && !options?.criticalLevel) return;

    const annotations: Record<string, AnnotationOptions> = {};
    if (options?.warningLevel) {
      annotations.warning = {
        type: 'line' as any,
        yMin: options.warningLevel,
        yMax: options.warningLevel,
        borderColor: theme.palette.warn.main,
        borderWidth: 1,
      };
    }

    if (options?.criticalLevel) {
      annotations.critical = {
        type: 'line' as any,
        yMin: options.criticalLevel,
        yMax: options.criticalLevel,
        borderColor: theme.palette.critical.main,
        borderWidth: 1,
      };
    }

    return annotations;
  }

  /*

   ({type: "time"} & ScaleTypeRegistry["time"]["options"])
     */
  function getScales(options?: TimeLineCardOptions): { [key: string]: ScaleOptionsByType } {
    const scales: { [key: string]: any } = {
      x: {
        title: {
          display: false,
          text: options?.xAxisLabel,
        },
        grid: {
          color: theme.palette.chartGrid.main,
          borderColor: theme.palette.chartGrid.main,
        },
        type: 'time' as any,
        time: {
          minUnit: 'second',
          tooltipFormat: 'YYYY-MM-DD HH:mm',
          displayFormats: {
            hour: 'HH:mm',
            day: 'YY-MM-DD',
            minute: 'HH:mm',
            second: 'HH:mm:ss',
          },
        },
        ticks: {
          autoSkip: false,
          autoSkipPadding: 20,
          maxRotation: 0,
          major: {
            enabled: true,
          },
          callback: (value: string, index: number, ticks: { value: number; major: boolean }[]) => {
            // first major tick should have full date info
            const firstMajorIdx = ticks.findIndex((x: { value: number; major: boolean }) => x.major);
            if (ticks[index] !== undefined && index === firstMajorIdx) {
              return moment(ticks[index].value).format('YYYY-MM-DD');
            }

            return value;
          },
        },
        max: zoomRange?.max,
        min: zoomRange?.min,
      },
      y: {
        title: {
          display: options?.yAxisLabel ? true : false,
          text: options?.yAxisLabel,
        },
        grid: {
          color: theme.palette.chartGrid.main,
          borderColor: theme.palette.chartGrid.main,
        },
        beginAtZero: true,
        max: options?.maxYAxisValue,
        min: options?.minYAxisValue,
      },
    };
    options?.additionalYAxis?.forEach((additionalAxis: TimeLineAdditionalAxis) => {
      scales[additionalAxis.id] = {
        title: {
          display: additionalAxis.display ? true : false,
          text: additionalAxis.label,
        },
        beginAtZero: true,
        position: additionalAxis.position,
        max: additionalAxis?.maxYAxisValue, // todo change to just max value
        min: additionalAxis?.minYAxisValue,
      };
    });

    return scales;
  }

  function getChartOptions(theme: Theme, options?: TimeLineCardOptions): ChartOptions<any> {
    const chartOptions: ChartOptions = {
      animations: false as any,
      parsing: false,
      maintainAspectRatio: false,
      responsive: process.env.NODE_ENV !== 'test',
      elements: {
        point: { radius: 0 },
      },
      interaction: {
        intersect: false,
      },
      scales: getScales(options),
      plugins: {
        legend: { display: true, align: 'center', position: options?.legendPosition ?? 'top' },
        title: {
          display: true,
          text: options?.title,
        },
        tooltip: {
          callbacks: {
            label: function (context: any) {
              let label = context.dataset.label || '';

              if (label) {
                label += ': ';
              }

              const precision = options?.tooltipPrecision || 2;
              if (context.parsed.y) {
                label += Math.round(context.parsed.y * 10 ** precision) / 10 ** precision;
              }
              return label;
            },
          },
        },
        zoom: {
          pan: {
            enabled: true,
            mode: 'x',
            modifierKey: 'ctrl',
            onPanComplete: async (event: any) => {
              const range = event.chart.scales.x._range;
              setZoomRange(range);
            },
          },
          zoom: {
            drag: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: 'x',
            onZoom: async (event: any) => {
              const range = event.chart.scales.x._range;
              setZoomRange(range);
            },
          },
        },
      },
      onClick: (event: any) => {
        setZoomRange(null);
        event.chart.resetZoom();
      },
    };
    chartOptions.plugins ??= {};
    const annotations = getAnnotations(theme, options);
    if (annotations) {
      chartOptions.plugins.annotation = { annotations };
    }

    return chartOptions;
  }

  const theme: Theme = useTheme();

  const chartOptions = getChartOptions(theme, options);

  return (
    <Card>
      <CardMedia component="div" sx={{ pr: 2 }}>
        <AspectRatioBox aspectRatio={aspectRatio} minHeight="200px">
          {loading || err ? (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          ) : (
            <Chart ref={chartRef} type="line" data={data} options={chartOptions} style={{ height: '100%' }} />
          )}
        </AspectRatioBox>
      </CardMedia>
      {err ? <Alert severity="error">{err}</Alert> : null}
    </Card>
  );
};

export default TimeLineCard;
