import type { ClientId } from 'context/clientId';
import { useQueries, UseQueryResult } from 'react-query';
import { timeseries } from 'helpers/api';
import valueConversion from 'helpers/valueConversion';

export type TimeseriesQuery = {
  source?: undefined;
  module?: 'measurement' | 'calculated';
  clientId: ClientId;
  objectId:
    | 'combustion'
    | 'gearbox'
    | 'shaft'
    | 'flowmeter'
    | 'speed'
    | 'direction'
    | 'position'
    | 'total'
    | 'environment'
    | 'performance'
    | 'waterJet';
  objectIdNo: number;
  pvId: string;
  pvIdNo: number;
  resolution: number;
  from: Date;
  to: Date;
};

const cacheTime = 60 * 1000;
const staleTime = cacheTime;

export type AvgQueryItem = { avg: number; _id: { tsp: number } };
export type AvgQueryResponse = AvgQueryItem[];

export type TimeseriesSequenceQuery = TimeseriesQuery & { sourceUnit?: string; targetUnit?: string };

function getQuery(query: TimeseriesSequenceQuery): TimeseriesQuery {
  const copy = { ...query };
  delete copy.sourceUnit;
  delete copy.targetUnit;
  return copy;
}

function getUnits(query: TimeseriesSequenceQuery): { sourceUnit?: string; targetUnit?: string } {
  return { sourceUnit: query.sourceUnit, targetUnit: query.targetUnit };
}

// better queryTimeseries function that already returns x,y points
async function queryTimeseriesSequence({
  queryKey,
  signal,
}: {
  queryKey: readonly unknown[] | [string];
  signal?: any;
}): Promise<{ x: number; y: number }[]> {
  if (queryKey.length !== 2) {
    throw new Error('unexpected query key length');
  }

  const data = queryKey[1] as TimeseriesQuery;
  const params = {
    module: data.module ?? 'measurement',
    clientId: data.clientId,
    objectId: data.objectId,
    objectIdNo: data.objectIdNo.toString(),
    pvId: data.pvId,
    pvIdNo: data.pvIdNo.toString(),
    resolution: data.resolution.toString(),
    to: data.to.toISOString(),
    from: data.from.toISOString(),
  };

  const response = await timeseries.get<AvgQueryItem[]>('api/measurement', { params, signal }).catch(err => {
    if (!(signal && signal.aborted)) {
      throw err;
    }
  });
  if (response === undefined || response === null) {
    throw new Error('no response');
  }
  const values = response.data;

  const xyValues = values.map(v => ({ y: v.avg, x: v._id.tsp }));
  return xyValues.sort((a, b) => a.x - b.x);
}

function useSequence(query: TimeseriesSequenceQuery) {
  return useSequences([query])[0];
}

function useSequences(query: TimeseriesSequenceQuery[]): UseQueryResult<{ x: number; y: number }[], unknown>[] {
  const tsQuery = query.map(getQuery);
  const unitConversions = query.map(getUnits);
  const res = useQueries(
    tsQuery.map(params => ({
      queryKey: ['timeseries-sequence', params] as const,
      queryFn: queryTimeseriesSequence,
      refetchOnWindowFocus: false,
      cacheTime,
      staleTime,
    }))
  );

  const resUnits = res.map((result, i) => {
    const sourceUnit = unitConversions[i].sourceUnit;
    const targetUnit = unitConversions[i].targetUnit;
    if (!sourceUnit) {
      return result;
    }
    if (!targetUnit) {
      return result;
    }

    if (result.data === undefined) {
      return result;
    }
    const convertedSequence = result.data.map(p => ({ ...p, y: valueConversion(p.y, sourceUnit, targetUnit) }));
    return { ...result, data: convertedSequence };
  });

  return resUnits;
}

function useSequencesData(query: TimeseriesSequenceQuery[]): [({ x: number; y: number }[] | undefined)[], boolean, string | undefined] {
  const results = useSequences(query);
  const loading = results.map(res => res.isLoading).some(i => i);
  const errMessages = results
    .map(res => res.error)
    .filter(e => e instanceof Error)
    .map(e => (e as Error).message);
  const errStrings = results.map(res => res.error).filter(e => typeof e === 'string');
  const errs = [...errMessages, ...errStrings];
  // todo typed/object return
  return [results.map(res => res.data), loading, errs.length ? errs.join(', ') : undefined];
}

export { useSequence, useSequences, useSequencesData };
