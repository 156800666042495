import React, { FC, useState } from 'react';
import { ZoomTimeRangeContext } from './ZoomTimeRangeContext';

export const ZoomTimeRangeProvider: FC = ({ children }) => {
  // holding the state of the current range
  const [range, setRange] = useState<{ min: number; max: number } | null | undefined>(null);

  // setting the zoom range
  const setZoomRange = (range: { min: number; max: number } | null | undefined) => setRange(range);

  const resetZoom = () => setZoomRange(null);

  return (
    <ZoomTimeRangeContext.Provider
      value={{
        zoomRange: range,
        setZoomRange: setZoomRange,
        resetZoom: resetZoom,
      }}>
      {children}
    </ZoomTimeRangeContext.Provider>
  );
};
